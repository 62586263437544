

<template>
  <section id="grading-assessment">
    <Breadcrumb
      :links="[
        {
          label: 'Grading',
          link: true,
          route: { name: 'Instructor Grading' },
        },
        {
          label: `${grading_title}`,
          link: false,
          route: null,
        },
      ]"
    />
    <!-- <v-text-field
      solo
      dense
      flat
      class="col-xl-3 col-lg-4 custom-border mb-5 general-custom-field f14 poppins fw500"
      hide-details
      prepend-inner-icon="mdi-magnify"
      placeholder="Search"
      label="Search"
      v-model="search"
    /> -->
    <SearchBar :placeholder="'Search assessment or evaluation'" :value.sync="search" @search="getAssessmentsAndEvaluation()"/>

    <v-data-table
      :headers="grading_evaluation_tbl"
      :items="grading"
      class="text--center custom-border poppins f14 mt-3"
      :loading="loading"
      :items-per-page.sync="paginate"
      hide-default-footer>
      <template v-slot:item.type_of_assessment="{ item }">
        <span class="text-capitalize">
          {{ item?.type_of_assessment ? item.type_of_assessment.replace('_', ' ') : ( item?.student_course_evaluations ? 'Course Evaluation' : 'Module Evaluation') }}
        </span>
      </template>
      <template v-slot:item.no_of_submitters="{ item }">
        <span>
          {{ item?.assessment_scores ? item.assessment_scores.length : ( item?.student_course_evaluations ? item.student_course_evaluations.length : item.student_module_evaluations.length) }}
        </span>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn
          text
          v-if="item?.type_of_assessment"
          class="primary--text text-capitalize"
          :to="{ name: 'Instructor Grading Assessment', params: { course_id: $route.params.id, id: item.id, course_name: item.name } }"
        >
          <v-icon left>mdi-eye-outline</v-icon>
          View
        </v-btn>
        <v-btn
          text
          v-if="item?.student_course_evaluations"
          class="primary--text text-capitalize"
          :to="{ name: 'Instructor Survey', params: { id: $route.params.id, survey_id: item.id, type: 'course_evaluation' } }"
        >
          <v-icon left>mdi-eye-outline</v-icon>
          View
        </v-btn>
        <v-btn
          text
          v-if="item?.student_module_evaluations"
          class="primary--text text-capitalize"
          :to="{ name: 'Instructor Survey', params: { id: $route.params.id, survey_id: item.id, type: 'module_evaluation' } }"
        >
          <v-icon left>mdi-eye-outline</v-icon>
          View
        </v-btn>
      </template>
    </v-data-table>
    <!-- <v-expansion-panels accordion focusable v-model="panel">
      <v-expansion-panel
        v-for="(item,i) in assessmentList"
        :key="i"
      >
        <v-expansion-panel-header class="poppins">{{ item.title }}</v-expansion-panel-header>
        <v-expansion-panel-content dense class="assessment" v-if="item.title !== 'Survey'">
          <v-text-field
            dense
            class="col-xl-3 col-lg-4 f16 poppins fw500 mt-5 mx-3"
            hide-details
            prepend-inner-icon="mdi-magnify"
            placeholder="Search"
            v-model="searchGraded"
            v-if="i === 0"
          />
          <v-text-field
            dense
            class="col-xl-3 col-lg-4 f14 poppins fw500 mt-5 mx-3"
            hide-details
            prepend-inner-icon="mdi-magnify"
            placeholder="Search"
            label="Search"
            v-model="searchUngraded"
            v-if="i === 1"
          />
          <v-data-table
            :headers="grading_assessment_tbl"
            :items="item.assessments"
            item-key="text"
            :search="i === 0 ? searchGraded : searchUngraded"
            class="px-3 poppins cursor-pointer"
            :page.sync="page"
            hide-default-footer
            :items-per-page="itemsPerPage"
            @page-count="pageCount = $event"
            @click:row="viewAssessment"
          >
            <template v-slot:item.title="{ item }">
              <span class="text-capitalize fw400 f14 primary--text cursor-pointer">
                {{ item.title }}
              </span>
            </template>
          </v-data-table>
          <v-row align="center" class="ma-2" v-if="item.assessments.length > 0">
            <v-col lg="1" class="hidden-md-and-down">
              <v-text-field
                :value="itemsPerPage"
                label="Items"
                type="number"
                min="-1"
                max="15"
                @input="itemsPerPage = parseInt($event, 10)"
              ></v-text-field>
            </v-col>

            <v-col lg="10" md="12">
              <v-pagination
                v-model="page"
                :length="pageCount"
                :total-visible="7"
              ></v-pagination>
            </v-col>
          </v-row>
        </v-expansion-panel-content>

        <v-expansion-panel-content dense class="assessment" v-if="item.title === 'Survey'">
          <v-text-field
            dense
            class="col-xl-3 col-lg-4 f16 poppins fw500 mt-5 mx-3"
            hide-details
            prepend-inner-icon="mdi-magnify"
            placeholder="Search"
            v-model="searchSurvey"
          />
          <v-data-table
            :headers="grading_evaluation_tbl"
            :items="item.assessments"
            item-key="id"
            class="px-3 poppins"
            hide-default-footer
            @click:row="viewEvaluation"
            :search="searchSurvey"
          >
            <template v-slot:item.title="{ item }">
              <section class="my-2">
                <div class="text-capitalize fw400 f14 primary--text cursor-pointer">
                  {{ item.title }}
                </div>
                <div class="f12 secondary--text"> {{ item.type }}</div>
              </section>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels> -->
                        
    <FormPagination 
      :pageCount="pageCount" 
      :page="page"
      :paginate="paginate"
      @page="(e) => {page = e, getAssessmentsAndEvaluation()}" 
      @paginate="(e) => {paginate = e, page = 1, getAssessmentsAndEvaluation()}"/>
  </section>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { grading_assessment_tbl, grading_evaluation_tbl } from '@/constants/tblheaders/grading';
import Breadcrumb from "../../layouts/teacher/InstructorBreadcrumbs.vue";

export default {
  components: { Breadcrumb },
  methods: {
    ...mapActions('instructor', ['getGradingAssessmentsEvaluations']),
    ...mapMutations('instructor', ['gradingMutation']),

    getAssessmentsAndEvaluation(){
      this.loading = true
      this.getGradingAssessmentsEvaluations({ id: this.$route.params.id, paginate: Number(this.paginate), page: this.page, search: this.search}).then(res => {
        this.grading_title = res.course_title
        this.pageCount = res.paginated_result.last_page
        this.paginate = res.paginated_result.per_page.toString()
        this.page = res.paginated_result.current_page
      }).finally(()=>{
        this.loading = false
      })
    },

    viewAssessment(e) {
      this.$router.push({ name: 'Instructor Grading Assessment', params: { id: e.id }, query: { page: 1 } })
    },

    viewEvaluation(e) {
      if(e.type === 'COURSE EVALUATION') {
        this.$router.push({ name: 'Instructor Survey', params: { id: this.$route.params.id, survey_id: e.id, type: 'course_evaluation' } })
      } else {
        this.$router.push({ name: 'Instructor Survey', params: { id: this.$route.params.id, survey_id: e.id, type: 'module_evaluation' } })
      }
    },
  },
  mounted(){
    this.getAssessmentsAndEvaluation()
  },
  created(){
    this.gradingMutation([])
  },
  computed:{
    ...mapState('instructor', {
      grading: (state) => state.grading,
      assessmentList(state){
        const _assessmentList = [
          {
            title: 'Graded Assessment',
            type_of_assessment: 'graded_assessment',
            assessments: []
          },
          {
            title: 'Ungraded Assessment',
            type_of_assessment: 'ungraded_assessment',
            assessments: []
          },
          {
            title: 'Survey',
            type_of_assessment: 'survey',
            assessments: []
          },
        ]
        state.grading.assessments.forEach(item => {
          let index = _assessmentList.findIndex(e => {return e.type_of_assessment === item.type_of_assessment})
          if(index != -1){
            _assessmentList[index].assessments.push(
              { 
                id: item.id,
                title: item.title?item.title: 'Untitled', 
                checking: item.assessment_scores.length,
                due_date: item.due_date ? !!JSON.parse(item.due_date).end ? JSON.parse(item.due_date).end : 'Not Assigned' : 'Not Assigned'
                //due_date: item.due_date.end ? 'Not assigned': JSON.parse(item.due_date).end
              }
            )
          }
        })

        state.grading.course_evaluations.forEach(item  => {
          _assessmentList[2].assessments.push(
              { 
                id: item.id,
                title: item.title, 
                type: 'COURSE EVALUATION',
                no_of_submitters: item.student_course_evaluations.length
                //due_date: item.due_date.end ? 'Not assigned': JSON.parse(item.due_date).end
              }
            )
        })

        state.grading.module_evaluations.forEach(item  => {
          _assessmentList[2].assessments.push(
              { 
                id: item.id,
                title: item.title, 
                type: 'MODULE EVALUATION',
                no_of_submitters: item.student_module_evaluations.length
                //due_date: item.due_date.end ? 'Not assigned': JSON.parse(item.due_date).end
              }
            )
        })
        
        return _assessmentList
      }
    }),
  },
  data: () => ({
    grading_assessment_tbl,
    grading_evaluation_tbl,
    search: '',
    grading_title: '',
    loading: true,
    pageCount: 1,
    paginate: '10',
    page: 1,
  }),
};
</script>

<style>
.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>